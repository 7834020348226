.sidebarOption {
  display: flex;
  align-items: center;
  cursor: pointer;
}

@media only screen and (max-width: 1000px) and (min-width: 600px){
  .sidebarOption > h2 {
    font-size: 2vw !important;
  }
}

@media only screen and (max-width: 600px) and (min-width: 400px){
  .sidebarOption > h2 {
    font-size: 0 !important;
  }
}

.sidebarOption:hover {
  background-color: #e8f5fe;
  border-radius: 30px;
  color: var(--twitter-color);
  transition: color 100ms ease-out;
}

.sidebarOption > .MuiSvgIcon-root {
  padding: 20px;
}

.sidebarOption > h2 {
  font-weight: 800;
  font-size: 20px;
  margin-right: 20px;
}

.sidebarOption--active {
  color: var(--twitter-color);
}
