.post {
  display: flex;
  align-items: flex-start;
  border-bottom: 1px solid var(--twitter-background);
  padding-bottom: 10px;
  max-width: 100% !important;

}

.a {
  display: block;
  cursor: pointer;
}

.post__body {
  flex: 1;
  padding: 10px;
  max-width: 100%;
  max-height: content-box;
}

.text{
  white-space: pre-wrap;
}

.post__body > img {
  border-radius: 20px;
  max-width: 100%;
  max-height: 45vh;
}

.player-wrapper{
  position: relative;
}

.player-wrapper > div{
  border-radius: 20px;
}

.news__a {
  text-decoration: none;
}

.news__card {
  padding-top: 1vh;
  padding-bottom: 1vh;
  float: left;
  position: relative;
  width: 75%;
}
.cardTextDiv {
  padding-left: 20px;
  font-size: smaller;
}

.post__footer {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  position: relative;
  min-width: 30%;
  max-width: 78%;
  padding-top: 2vh;
}

.post__headerDescription {
  margin-bottom: 10px;
  font-size: 15px;
}

.post__headerText > h3 {
  font-size: 15px;
  margin-bottom: 5px;
  cursor: pointer;
}

.post__badge {
  font-size: 14px !important;
  color: var(--twitter-color);
}

.post__headerSpecial {
  font-weight: 600;
  font-size: 12px;
  color: gray;
}

.post__avatar {
  padding: 20px;
}
