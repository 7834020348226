.sidebar {
  border-right: 1px solid var(--twitter-background);
  flex: 0.5;
  /* min-width: 250px; */
  margin-top: 20px;
  padding-left: 0.2vw;
  padding-right: 0.2vw;
  max-width: 35vw;
}

@media only screen and (max-width: 420px){
  .sidebar {
    display: none !important;
  }
}

@media only screen and (max-width: 1024px) and (min-width: 420px){
  .sidebar {
    max-width: 40%;
  }
}

.sidebar__twitterIcon {
  color: var(--twitter-color);
  font-size: 30px !important;
  margin-left: 20px;
  margin-bottom: 20px;
  max-width: 2vw;
}

.sidebar__tweet {
  background-color: var(--twitter-color) !important;
  border: none !important;
  color: white !important;
  font-weight: 900 !important;
  text-transform: inherit !important;
  border-radius: 30px !important;
  height: 50px !important;
  margin-top: 20px !important;
  width: 90%;
}
