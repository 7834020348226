.feed {
  flex: 50%;
  border-right: 1px solid var(--twitter-background);
  padding-bottom: 100px;
  overflow-y: scroll;
}

@media only screen and (max-width: 700px){
  .feed{
    max-width: 100% !important;
  }
}

@media only screen and (min-width: 700px){
  .feed{
    max-width: 50% !important;
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.feed::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.feed {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.feed__header {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 100;
  border: 1px solid var(--twitter-background);
  padding: 15px 20px;
}

.feed__header > h2 {
  font-size: 20px;
  font-weight: 800;
}

.topIconDiv {
  justify-content: space-between;
  display: flex;
  padding-right: 5vw;
  padding-left: 5vw;
}

.topIcon {
  min-width: 33vw !important;
  cursor: pointer;
}

.button{
  color: var(--twitter-background);
  padding: 100px;
  flex:content !important;
}