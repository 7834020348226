.widgets {
  flex: 0.3;
  min-width: 20vw;
  padding-top: 5vh;
  width: 30%;
}

@media only screen and (max-width: 1024px) {
  .widgets {
    /*display: none !important;*/
    min-width: 100vw !important;
    padding: 0 !important;
    position: absolute !important;
  }
}

.widgets__input {
  display: flex;
  align-items: center;
  background-color: var(--twitter-background);
  padding: 10px;
  border-radius: 20px;
  margin-top: 10px;
  margin-left: 20px;
}

.widgets__input > input {
  border: none;
  background-color: var(--twitter-background);
}

.widgets__searchIcon {
  color: gray;
}

.topTextContainer {
  margin-top: 15px;
  margin-left: 20px;
  background-color: #f5f8fa;
  border-radius: 20px;
}

.topTextContainer__div {
  padding-bottom: 20px;
}

.topText {
  font-size: 20px;
  font-weight: 800;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
}

